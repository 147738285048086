





























import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { getComponent } from '@/utils/helpers';

  @Component({
    components: {
      WellSignalsView: () => getComponent('tasqs/WellSignalsView'),
    },
  })
export default class WellSignalsViewPopup extends mixins() {


}

